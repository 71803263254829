export const useGeocode = (key = 'useGeocode') => {
  const { data: dataRaw, fetch, fetching } = useFimRpc('getGeocoder', key)

  const data = computed(() => {
    return dataRaw.value as google.maps.GeocoderResponse | null
  })

  return {
    data,
    fetch: handledRpc(fetch, {
      rpc: 'useGeocode',
      service: 'google-api',
    }),
    fetching: computed(() => fetching.value),
  }
}
